<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-col cols="2">
            <v-avatar size="100">
              <v-img src="https://cdn.vuetifyjs.com/images/john.jpg"></v-img>
            </v-avatar>
          </v-col>
          <v-col class="px-0 pt-0">
            <v-list-item class="text-left">
              <v-list-item-content>
                <v-list-item-title>
                  <strong>Account Name:</strong>
                  <span :style="img_box.style" :class="img_box.class">
                    {{ user.fullName }}
                  </span>
                </v-list-item-title>
                <v-list-item-title>
                  <strong>Account Number:</strong>
                  <span :style="img_box.style" :class="img_box.class">
                    {{ user.account_number }}
                  </span>
                </v-list-item-title>
                <v-list-item-title>
                  <strong>Phone Number:</strong>
                  <span :style="img_box.style" :class="img_box.class">
                    {{ user.phone }}
                  </span>
                </v-list-item-title>
                <v-list-item-title>
                  <strong>Email Address:</strong>
                  <span :style="img_box.style" :class="img_box.class">
                    {{ user.email }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="primary--text">
            Transactions Summary
          </v-card-title>
          <v-list-item class="text-left">
            <v-list-item-content>
              <v-list-item-title>
                <strong>Account Balance: </strong>
                <span :style="img_box.style" :class="img_box.class">
                  {{ helpers.nairaSign() + "" + user.balance }}
                </span>
              </v-list-item-title>
              <v-list-item-title>
                <strong>Last Transaction: </strong>
                <span :style="img_box.style" :class="img_box.class">
                  {{ helpers.nairaSign() + "" + user.balance }}
                </span>
              </v-list-item-title>
              <v-list-item-title>
                <strong>Last Deposit: </strong>
                <span :style="img_box.style" :class="img_box.class">
                  {{ helpers.nairaSign() + "" + user.balance }}
                </span>
              </v-list-item-title>
              <v-list-item-title>
                <strong>Last Withdrawal: </strong>
                <span :style="img_box.style" :class="img_box.class">
                  {{ helpers.nairaSign() + "" + user.balance }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div class="d-flex justify-lg-space-around mx-3 mt-1">
            <v-text-field
              dense
              v-model="form.amount"
              outlined
              type="number"
              placeholder="Please enter amount to send to user (e.g 1000)"
            ></v-text-field>
            <v-btn class="ml-2" color="primary" :loading="loading">Send</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar class="primary--text text-h6" flat>
            Recent Transactions
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="transactions"
            :loading="loadTransactions"
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Helpers from "../../utils/helpers";
import { mapGetters } from "vuex";
export default {
  name: "HelloWorld",
  data() {
    return {
      img_box: {
        style: this.$vuetify.breakpoint.mdAndUp
          ? "position: absolute; left: 170px"
          : "",
        class: this.$vuetify.breakpoint.smAndDown ? "d-block" : "",
      },
      helpers: new Helpers(),
      form: {},
      loading: false,
      loadTransactions: false,
      headers: [
        { text: "#", value: "numbers", sortable: false, orderable: false },
        { text: "Amount", value: "amount" },
        { text: "Reference", value: "reference" },
        { text: "Type", value: "type" },
        { text: "Sender", value: "sender" },
        { text: "Recipient", value: "recipient" },
        { text: "Status", value: "status" },
        { text: "Activity", value: "activity" },
      ],
      transactions: [],
    };
  },
  computed: {
    ...mapGetters("users", ["user"]),
  },
  created() {},
};
</script>
